<template>
    <div>
        <app-navbar21/>
        <div :class="$style.mainContents">
            <router-view/> <!-- 내용이 바뀔 영역 -->
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import AppNavbar21 from '../components/navbar21'

export default defineComponent({
  name: "Homepage",
  components: {
    AppNavbar21,
  }, 
});
</script>

<style module>
  .mainContents {
    width: 100%;
    overflow: auto;
    height:100%;
    margin-top: 100px;
  }
</style>
