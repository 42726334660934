
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import LayoutMain from './views/LayoutMain'
import Home from './views/home'
import Service from './views/services'
import NotFound from './views/not-found'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  // mode: 'history',
  routes: [
    { //LayoutMain에 해당하는 페이지들
      path: '',
      component: LayoutMain,
      children: [
        {
          name: 'Home',
          path: '/',
          component: Home,
        },
        {
          name: 'Services',
          path: '/services',
          component: Service,
        },
        {
          name: '404 - Not Found',
          path: '**',
          component: NotFound,
          fallback: true,
        },
      ]     
    },
  ],
})

